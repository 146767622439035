// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import ConfigProvider from 'antd/lib/config-provider'
import '@bodhi-project/antrd/lib/4.10.3/config-provider/style/css'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import Link from '../components/link'
import '../components/link/style.less'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Homepage',
  nakedPageSlug: '',
  pageAbstract:
    'Prisma Design is a multiverse project set in Auroville, south India, bringing together design, technology and creative imagination for a luminous future. Prisma Design is incubated by Prisma, and housed in Aurelec, Auroville.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logos/logo.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 900
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = (props) => (
  <ConfigProvider prefixCls="wtf">
    <StandardPageWrapper className="home-page" seoData={seoData} {...props}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          maxHeight: '100%',
          minWidth: 'calc(100vw - 4rem)',
          zIndex: 5,
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            maxWidth: '36rem',
            height: '1000vh',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderLeft: '2px solid var(--gray-9)',
          }}
        >
          &nbsp;
        </div>
      </div>
      <p
        style={{
          marginBottom: 0,
          paddingTop: '12rem',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          zIndex: 100,
          position: 'relative',
          color: 'var(--gray-9)',
        }}
      >
        Prisma Design is a multiverse project bringing together design,
        technology and creative imagination for a luminous future. Our small and
        international team honours the legacy of the pioneering spirit of
        Auroville - a boundless adventure of evolutionary consciousness, being
        lived and played out every day in the world’s largest experimental
        international township. Prisma Design is incubated by Prisma, and housed
        in Aurelec, Auroville.
        <br style={{ display: 'block', marginTop: '0.625rem' }} />
        Explore the rest of our{' '}
        <Link to="https://www.prisma.haus">Prisma Haus</Link>
        &nbsp;–&nbsp;
        <Link to="https://books.prisma.haus">Prisma Books</Link>
        &nbsp;•&nbsp;
        <Link to="https://dreams.prisma.haus">Prisma Dreams</Link>
        &nbsp;•&nbsp;
        <Link to="https://arts.prisma.haus">Prisma Arts</Link>
      </p>
    </StandardPageWrapper>
  </ConfigProvider>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
